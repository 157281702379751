<template>
  <div class="dashboard text-white pb-20" v-if="schedules">
    <Header />
    <div class="px-4 my-4">
      <div class="text-nColorLightGray mb-4 text-lg w-full">
        <div
          class="w-full font-ZuumeSemiBold text-nColorYellow text-5xl text-center"
        >
          {{ businessName }}
        </div>
        <div
          class="border border-nColorYellow rounded-2xl shadow-orange-right px-4 py-1 grid gap-1 mt-3"
        >
          <div>Total Users: {{ schedules.total_customers }}</div>
          <div>Total Game Plays: {{ schedules.total_gameplays }}</div>
          <div>Average Game Rating: {{ schedules.average_game_rating }}</div>
          <div>Stickiness: {{ schedules.stickiness }}</div>
        </div>
      </div>
      <div class="border border-nColorYellow rounded-3xl">
        <div class="flex">
          <div
            id="active"
            :class="{
              'bg-transparent text-white': !active,
              'bg-nColorOrange text-black': active,
            }"
            class="rounded-3xl w-1/2 py-1 font-InterMedium font-bold text-center m-0.5"
            @click.prevent="showActiveGames()"
          >
            Active Games
          </div>
          <div
            id="active"
            :class="{
              'bg-transparent text-white': !completed,
              'bg-nColorOrange text-black': completed,
            }"
            class="rounded-3xl w-1/2 py-1 font-InterMedium font-bold text-center m-0.5"
            @click.prevent="showCompletedGames()"
          >
            Completed Games
          </div>
        </div>
      </div>
      <div>
        <div v-if="active">
          <div v-if="schedules.active.length === 0">
            <div class="w-full p-10 text-nColorSubTextGray text-center">
              You have no active schedules
            </div>
          </div>
          <div v-else>
            <div v-for="game in schedules.active" :key="game">
              <router-link
                :to="{
                  name: 'Stats',
                  params: {
                    business: business,
                    schedule_id: game.schedule_id,
                  },
                }"
                class="text-gray-600 text-xs md:text-xl font-medium"
              >
                <div
                  class="border border-nColorYellow rounded-2xl shadow-orange-right p-2 my-3 flex gap-2"
                >
                  <div>
                    <img :src="game.game.icon" width="50" height="50" />
                  </div>
                  <div class="grid content-center">
                    <div class="font-ZuumeSemiBold text-nColorYellow text-3xl">
                      {{ game.game.name }}
                    </div>
                    <div
                      class="font-InterRegular text-nColorSubTextGray text-xs"
                    >
                      Ending at {{ game.ending }}
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div v-if="completed">
          <div v-if="schedules.completed.length === 0">
            <div class="w-full p-10 text-nColorSubTextGray text-center">
              You have no completed schedules
            </div>
          </div>
          <div v-else>
            <div v-for="game in schedules.completed" :key="game">
              <router-link
                :to="{
                  name: 'Stats',
                  params: {
                    business: business,
                    schedule_id: game.schedule_id,
                  },
                }"
                class="text-gray-600 text-xs md:text-xl font-medium"
              >
                <div
                  class="border border-nColorYellow rounded-2xl shadow-orange-right p-2 my-3 flex gap-2"
                >
                  <div>
                    <img :src="game.game.icon" width="50" height="50" />
                  </div>
                  <div class="grid content-center">
                    <div class="font-ZuumeSemiBold text-nColorYellow text-3xl">
                      {{ game.game.name }}
                    </div>
                    <div
                      class="font-InterRegular text-nColorSubTextGray text-xs"
                    >
                      Completed at {{ game.ended }}
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fixed bottom-0 w-full">
      <div class="w-full grid">
        <div
          class="goto-game-button fixed bottom-0 w-screen bg-nColorOrange rounded-t-2xl z-20"
        >
          <button
            :class="[
              'text-nColorDarkGray font-ZuumeSemiBold text-3xl flex w-full justify-center items-center py-2 gap-2 h-14',
              { disabled: isLoading },
            ]"
            @click.prevent="downloadReport"
            :disabled="isLoading"
          >
            <span v-if="isLoading">
              <svg
                width="60"
                height="15"
                viewBox="0 0 120 30"
                xmlns="http://www.w3.org/2000/svg"
                fill="#000"
              >
                <circle cx="15" cy="15" r="15">
                  <animate
                    attributeName="r"
                    from="15"
                    to="15"
                    begin="0s"
                    dur="0.8s"
                    values="15;9;15"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="fill-opacity"
                    from="1"
                    to="1"
                    begin="0s"
                    dur="0.8s"
                    values="1;.5;1"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
                <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                  <animate
                    attributeName="r"
                    from="9"
                    to="9"
                    begin="0s"
                    dur="0.8s"
                    values="9;15;9"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="fill-opacity"
                    from="0.5"
                    to="0.5"
                    begin="0s"
                    dur="0.8s"
                    values=".5;1;.5"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
                <circle cx="105" cy="15" r="15">
                  <animate
                    attributeName="r"
                    from="15"
                    to="15"
                    begin="0s"
                    dur="0.8s"
                    values="15;9;15"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="fill-opacity"
                    from="1"
                    to="1"
                    begin="0s"
                    dur="0.8s"
                    values="1;.5;1"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
              </svg>
            </span>
            <span v-else>Download Report</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Header from "@/components/Elements/Header.vue";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  name: "Dashboard",
  components: { Header },
  data() {
    return {
      schedules: null,
      active: true,
      isLoading: false,
      completed: false,
      business: this.$route.params.business,
      businessName: this.$store.getters.businessName,
    };
  },
  created() {
    ApiService.get(apiResource.getBusinessSchedules, {
      business_slug: this.$route.params.business,
    }).then((data) => {
      this.schedules = data.data.data;
      if (
        this.schedules.completed.length != 0 &&
        this.schedules.active.length === 0
      ) {
        this.showCompletedGames();
      }
    });
  },
  mounted() {},
  methods: {
    showActiveGames() {
      this.active = true;
      this.completed = false;
    },
    showCompletedGames() {
      this.active = false;
      this.completed = true;
    },
    downloadReport() {
      this.isLoading = true;
      ApiService.get(apiResource.downloadReport, {
        business_slug: this.$route.params.business,
      })
        .then(() => {
          this.isLoading = false;
          toast.success("Report sent to your email!", { timeout: 2000 });
        })
        .catch(() => {
          this.isLoading = false;
          toast.error("Something went wrong. Please try again later", {
            timeout: 2000,
          });
        });
    },
  },
};
</script>

<style scoped>
.dashboard {
  min-height: 100dvh;
}
</style>
